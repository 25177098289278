$clr_blue: #609090;
$clr_gray: #a0a0a0;
$url_root_images_system: 'img/system';


.apiErrorMessageBox{
  position: fixed;
  top: 10px;
  left: 10px;
  width: 80%;
  background-color: white;
  height: 80%;
  padding: 20px;
  font-size: 0.8em;
  border: 1px solid gray;
  z-index: 100;
}

.fejleckep{
  width: 920px;
  height: 130px;
  background-image: url(#{$url_root_images_system + "/erkel_fejlec_szulok.png"}); 
  background-repeat: no-repeat;
  border-bottom: 0px solid lightgray;
  margin-bottom: 2em;
  margin: 0 auto;
}


.btn.btn-default{
  border: 1px solid #aaaaaa !important;
}

.appHeader {
  //background: rgb(108,20,31);
  //background: radial-gradient(circle, rgba(108,20,31,1) 0%, rgba(42,6,2,1) 100%);  
  //padding: 1vh;
  height: 10vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  //margin-bottom: 2px;
  margin-left: 0px;
  margin-right: 0px;
  //margin-top: 10px;
  //border-radius: 10px;
  text-align: center;
}

.appHeader select{
  font-size: 0.9rem;
  width: 100%;
}

.app-emailcim{
  color: white;
}

.appHeader .iskolaneve{
  font-size: 0.9rem;
  width: 920px;
  text-align: right;
}

.nem{
  color: red;
}

.appHeader div.kisbetu{
  font-weight: 100;
  font-size: 18px;
}

.pageContainer{
  height: 87vh;
  padding-top: 60px;
}

.infoscroll{
  border: 1px solid lightgray;
  padding: 1vh;
  margin-top: 3vh;
  height: 50vh;
  background: #ffffee;
  overflow-y: scroll;
}

.page{
  height:100%;
}


.infoText{
  font-size: 1em;
  background-color: #eeeeee;
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
}


.utasitas{
  font-size: 1.2em;
  font-weight: bold;
}
.utasitas-megjegyzes{
  font-size: 0.8;
  font-weight: bold;
  color:red;
}
.utasitas-megjegyzes.kisero{
  /* color:red !important; */
}

.adatHolder{
  margin-top: 10px;
  padding-left: 20px;
  border-left: 8px solid lightgray !important;
  .adatNev{
    font-weight: bold;
    margin-bottom: 4px;
  }
  .adatMegjegyzes{
    font-size: 14px;
  }
  input{
    width: 400px;
    border-radius: 2px;
    border: 1px solid lightgray !important;
    padding: 4px;
  }
}

.reg_kozepre{
  text-align: center;
  div{
    text-align: center;
    margin-bottom: 20px;
  }
}

.regisztracio{
  .regisztracio_fejlec{
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #609090;
    padding:10px;
    display: flex;
    align-items: center;
    position: relative;
    .regisztracio_inside{
      margin: 10px;
      .bold{
        font-weight: bold;
      }
    }
    .regisztracio_inside.vendeg{
      width: 160px;
    }
    .regisztracio_inside_button{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .figy_uzenet{
    text-align: center;
    color: red;
  }

  .btnStripe{
    width: 200px;
    height: 100px;
    overflow: auto;
    overflow-y: hidden;
    border: 1px solid #204090;
    padding-bottom: 40px;
    text-align:center;
    border-radius: 10px;
    cursor: pointer;
    margin-left:auto;
    margin-right:auto;
    margin-top: 20px;
    .icon{
      width: 40%;
    }
    .text{
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      color: #204090;
    }
  }  
}

/* -------------------------------- RENDEZVENY-VALASZTAS  ------------------------------------ */


.cim_aktualis_rendezvenyeink{
  font-size: 1.4em;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vevoAdatFejlec{
  border: 1px solid lightgray;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  background-color: #eeeeee;
  .felirat{
    color: darkgreen;
  }
  .adat{
    font-weight: bold;
    color: darkgreen;
  }
}


.rendezvenyCsoportCim{
  padding: 4px;
  padding-left: 10px;
  
  text-align: center;
  font-weight: bold;
  background-color: #eeeeee;
  /*
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  */
}

.rendezvenybox{
  overflow: auto;
  min-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  clear: both;

  
  border-bottom: 1px solid lightgray;

  .iconbox{
    //width: 140px;
    margin-top: 8px;
    img.icon{
      width: 100%;
    }
  }

  .cimbox{
    //width: calc(100% - 280px);
    padding-bottom: 20px;
    .cim{
      text-align: left;
      font-weight: bold;
      font-size: 1.2em;
    }
    .alcim{
      text-align: left;
      font-weight: bold;
    }  
    .rovidInfo{
      //display: block;
    }
    .rovidInfo.nincs-szabad-ferohely{
      color: red;
    }
    .btnReszletesInfo{
      overflow: auto;
      cursor: pointer;
      .btnInfoIcon{
        float:left;
      }
      .btnInfoText{
        float:left;
        padding-left: 6px;
        font-size: 0.9em;
        padding-top: 2px;
      }
    }
    .onMarRegisztralt{
      overflow: auto;
      .icon{
        float:left;
      }
      .text{
        float:left;
        padding-left: 6px;
        font-size: 0.9em;
        padding-top: 2px;
      }
    }
    .onMarRegisztralt.red{
      color: red;
      font-weight: bold;
      width: fit-content;
      animation: blinker 1s linear infinite;
    }
    
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }    
  }
  .regbox{
    //width: 140px;
  }
}

/*
.rendezveny_button.kattinthato{
  cursor: pointer;
}

.rendezveny_button.kattinthato:hover{
  background-color: white
}

.rendezveny_button.nemkattinthato{
  cursor: arrow !important;
}
*/




/* -------------------------------- REGISZTRACIO ------------------------------------ */


.vendegek_container{
  padding-left: 0px;
  margin-right: 0px;
  .vendegek_cim{
    margin: 10px;
    text-align: center;
    font-weight: bold;
  }
  .vendegek_flex{
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #609090;
    padding:10px;
    display: flex;
    align-items: center;
    position: relative;
    .vendegek_inside{
      margin: 10px;
      .bold{
        font-weight: bold;
      }
    }
    .vendegek_inside.vendeg{
      width: 160px;
    }
    .vendegek_inside_button{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.btnVendegHozzaadasaContainer{
  .btnVendegHozzaadasa{
    padding: 10px;
    border: 2px solid #609020;
    border-radius: 10px;
    width: 400px;
    display: flex;
    float:left;
    margin-right: 20px;
    background-color: lightgray;
    cursor: pointer;
    .icon{
      margin-right: 10px;
    }
    .text-kicsi{
      font-size: 0.9em;
      font-weight: normal;
    }
    .text-nagy{
      font-size: 1.2em;
      font-weight: bold;
    }
    .text-nagy-kozepre{
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
    }
  }
  .btnVendegHozzaadasa:hover{
    background-color: white
  }  
}



/* --------------------------------------------------------------- */

.modalSzamlazasiAdatok{
  height:calc(100% - 100px);
  .contentSzamlazasiAdatok{
    height:100%;
  }
  .cimTitle{
    text-align: center;
    font-size: 1.2em;
    padding: 10px;
  }
}


.szamlazasiAdatokDisplay{
  clear: both;
  border-radius: 10px;
  border: 1px solid darkcyan;
  text-align: center;
  .cimTitle{
    padding: 8px;
    background-color: darkcyan;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: bold;
  }
  .body{
    .btnEdit{
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
            
      overflow: auto;
      cursor: pointer;
      .icon{
        float:left;
      }
      .text{
        float:left;
        padding-left: 6px;
        font-size: 0.9em;
        padding-top: 2px;
      }
    }

    padding: 10px;
    .cegnevSzemelynev{
      font-size: 1.2em;
      font-weight: bold;
    }
    .irszTelepules{

    }
    .cim{

    }
    .adoszam{

    }
  }
}

/* --------------------------------------------------------------- */

.onLineFizetes{
  background-color: red;
}

/* --------------------------------------------------------------- */

.jegylista{
  margin-top: 10px;
  padding: 10px;
  .jegy{
    margin-bottom: 10px;
    border: solid 1px darkgreen;
    border-radius: 10px;
    overflow: auto;
    .cimbox{
      float:left;
      width: 200px;
      background-color: darkcyan;
      color: white;
      .cim{
        text-align: center;
        padding-top: 10px;
        font-size: 1.2em;
        font-weight: bold;
      }
      .ar{
        text-align: center;
        padding-bottom: 10px;
      }
    }
    .darabbox{
      float:left;
      .plusminusbtn{
        float:left;
        width: 80px;
        padding: 11px;
        text-align: center;
        cursor: pointer;
      }
      .szam{
        float:left;
        width: 20px;
        height: 50px;
        margin-top: 6px;
        font-size: 2em;
        font-weight: bold;
        width: 50px;
        text-align: center;
      }
      .db{
        float:left;
        font-size: 1.4em;
        margin-top: 20px;
        width: 40px;
        text-align: center;
      }
    }
    .arbox{
      float:left;
      width: calc(100% - 470px);
      .szam{
        float:left;
        font-size: 1.4em;
        margin-top: 16px;
        width: calc(100% - 40px);
        font-weight: bold;
        text-align: right;
        padding-right: 10px;
      }
      .ft{
        float:left;
        margin-top: 20px;
        width: 38px;
      }
    }
  }
  .osszesen{
    .szam{
      float: right;
      padding: 10px;
      font-size: 1.4em;
      font-weight: bold;
      padding-bottom: 0px;
    }
    .ft{
      float: right;
      padding-top: 14px;
      padding-right: 48px;
    }
    .osszesenFelirat{
      float: right;
      padding-top: 14px;
      padding-right: 48px;
    }
  }
  .osszesenFeliratMegjegyzes{
    clear: both;
    text-align: right;
    padding-right: 50px;
    font-size: 0.8em;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.reviewBox{
  padding:20px;
  .info{
    text-align: center;
  }
  .info.nefeledje{
    padding:20px;
    border:1px solid lightgray;
  }
}

/* ------------------------------------------------ modal vendeg ----------------------------------- */
.modalVendeg{
  .ugyanaz_az_idosav{
    text-align: center;
    color: red;
    padding: 5px;
    margin-top: 10px;
  }

  .van_hangszer_de_nincs_idopont{
    text-align: center;
    color: red;
    padding: 5px;
    margin-top: 10px;
  }

  .nincs_nev{
    text-align: center;
    color: red;
    padding: 5px;
    margin-top: 10px; 
  }
  ul{
    margin-bottom: 0px;
  }
  .jegyTipusMegjegyzes{
    padding: 10px;
    background-color:#eeeeee;
    margin-top: 10px;
  }
}


/* ----------------------------------- vevoemailmegadas -------------------- */

.vevoEmailMegadas{
  .text-muted{
    margin-top: 10px !important;
  }
  .kerjuk_varjon{
    padding: 20px;
    text-align: center;
  }
  .elfogadoszoveg{
    display: inline-block;
  }
}